// src/services/inventoryService.js
import api from './api';

class InventoryService {
    getGames() {
        return api.get('inventory/games/');
    }

    getGameById(gameId) {
        return api.get(`inventory/games/${gameId}/`);
    }

    addGame(game) {
        return api.post('inventory/games/', game);
    }

    updateGame(gameId, updatedData) {
        return api.put(`inventory/games/${gameId}/`, updatedData);
    }

    deleteGame(gameId) {
        return api.delete(`inventory/games/${gameId}/`);
    }
}

export default new InventoryService();
