<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Perfil">
          <!-- Show the loading spinner when fetching data -->
          <div v-if="loading" class="text-center my-5">
            <b-spinner label="Loading..."></b-spinner>
            <p>Cargando perfil...</p>
          </div>

          <!-- Display user profile information once data is fetched -->
          <div v-if="!loading && user">
            <p><strong>Nombre de usuario:</strong> {{ user.username }}</p>
            <p><strong>Nombre:</strong> {{ user.first_name }}</p>
            <p><strong>Apellidos:</strong> {{ user.last_name }}</p>
            <p><strong>Correo electrónico:</strong> {{ user.email }}</p>
            <p><strong>Número de teléfono:</strong> {{ user.phone_number }}</p>
            <p><strong>DNI:</strong> {{ user.dni }}</p>
            <p><strong>Dirección:</strong> {{ user.address }}</p>
            <p><strong>Usuario de telegram:</strong> {{ user.telegram_username }}</p>
            <p><strong>Tiene suscripción activa:</strong> {{ user.has_active_subscription ? 'Si' : 'No' }}</p>

            <div v-if="loggedInUser?.is_superuser">
              <h4>Administrador</h4>
              <p><strong>[ADMIN] ID del cliente de Stripe:</strong> {{ user.stripe_customer_id }}</p>
            </div>

            <b-button class="change-profile" v-if="canSeeManageSubscriptionButton" variant="primary" @click="redirectToStripePortal" :disabled="manageSubscriptionButtonDisabled">
              Gestionar suscripción
            </b-button>
            <b-button v-if="canSeeManageSubscriptionButton" variant="secondary" @click="editProfile">
              Editar Perfil
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import { useUserStore } from '@/stores/userStore';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const userStore = useUserStore();
    const user = ref(null);
    const loading = ref(true); // Add loading state
    const manageSubscriptionButtonDisabled = ref(false);

    const loggedInUser = computed(() => userStore.getLoggedInUser);
    const canSeeManageSubscriptionButton = computed(
      () => user.value?.has_active_subscription && !user.value?.hide_manage_subscription
    );

    const redirectToStripePortal = () => {
      manageSubscriptionButtonDisabled.value = true;
      AuthService.createStripeCustomerPortal().then(
        (response) => {
          window.location.href = response.data.url;
        },
        (error) => {
          console.error(error);
        }
      );
    };

    const editProfile = () => {
      router.push(`/update-profile/${loggedInUser.value.id}`);
    };

    onMounted(() => {
      const userId = route.params.id;
      AuthService.getUserProfile(userId)
        .then((response) => {
          user.value = response.data;
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          loading.value = false; // Disable loading once data is fetched
        });

      const activeSubscription = route.query.active_subscription;
      if (activeSubscription !== undefined) {
        userStore.updateHasActiveSubscription(activeSubscription);
      }
    });

    return {
      user,
      loggedInUser,
      manageSubscriptionButtonDisabled,
      redirectToStripePortal,
      editProfile,
      canSeeManageSubscriptionButton,
      loading, // Expose loading to the template
    };
  },
};
</script>

<style>
.change-profile {
  background-color: #e01414 !important; /* Custom background color */
  color: #fff; /* Custom text color */
  margin-right: 20px;
}

.text-center {
  text-align: center;
}

.my-5 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
</style>
