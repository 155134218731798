<template>
  <div id="app">
    <b-navbar class="navbar" toggleable="lg">
      <b-navbar-brand href="/">Intranet Valentia Lúdica</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="!loading">
          <b-nav-item class='button' v-if="!loggedInUser" href="/login">Iniciar sesión</b-nav-item>
          <b-nav-item class='button' v-if="!loggedInUser" href="/register">Registrarse</b-nav-item>
          <b-nav-item class='button' v-if="loggedInUser" href="/profile">Perfil</b-nav-item>
          <b-nav-item class='button' v-if="loggedInUser && loggedInUser?.is_superuser" href="/users">Listado de usuarios</b-nav-item>
          <b-nav-item class='button' v-if="loggedInUser && !loggedInUser?.has_active_subscription" href="/subscribe">Suscribirse</b-nav-item>
          <b-nav-item class='button' v-if="loggedInUser" @click="logout">Salir</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-container>
      <BToastOrchestrator />
      <router-view/>
    </b-container>
  </div>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useUserStore } from '@/stores/userStore';
import AuthService from '@/services/authService';
import { useRouter } from "vue-router";

export default {
  name: 'App',
  setup() {
    const loading = ref(true);
    const userStore = useUserStore();
    const router = useRouter();

    const loggedInUser = computed(() => userStore.getLoggedInUser);
    const isUserLoggedIn = computed(() => userStore.isUserLoggedIn);

    const logout = () => {
      AuthService.logout();
      userStore.cleanLoggedInUser();

      router.push('/login');
    };

    onMounted(() => {
      if(isUserLoggedIn.value && loggedInUser.value === null) {
        AuthService.getUserProfile().then(
            response => {
              userStore.saveLoggedInUser(response.data);
              loading.value = false;
            },
            error => {
              userStore.cleanLoggedInUser();
              console.error(error);
              loading.value = false;
            }
        );
      } else {
        loading.value = false;
      }
    });

    return {
      loading,
      userStore,
      logout,
      loggedInUser
    };
  }
};
</script>

<style>
.navbar {
  background-color: #e01414; /* Custom background color */
  color: #fff;               /* Custom text color */
  margin-bottom:20px;
}

.navbar .navbar-nav .nav-link {
  color: #fff; /* Custom link color */
}

.navbar .navbar-brand {
  color: #fff; /* Custom brand color */
}
.button {
  background-color: #00000071 !important;  /* Custom background color */
  color: #fff;               /* Custom text color */
  border-radius: 50px;
  width:auto;
  padding:1px;
  margin-left:10px;
}
</style>
