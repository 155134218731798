<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Crear Encuesta">
          <div>
            <p><strong>Título de la encuesta:</strong></p>
            <b-form-input v-model="pollTitle" placeholder="Introduce el título de la encuesta" />

            <p><strong>Respuestas:</strong></p>
            <div v-for="(answer, index) in answers" :key="index">
              <b-form-input v-model="answers[index]" :placeholder="'Respuesta ' + (index + 1)" />
              <b-button variant="danger" @click="removeAnswer(index)">Eliminar</b-button>
            </div>
            <b-button variant="primary" @click="addAnswer">Añadir respuesta</b-button>

            <p><strong>Fecha de cierre:</strong></p>
            <Datepicker v-model="closingDate" :format="'YYYY-MM-DD'" />

            <p><strong>Hora de cierre:</strong></p>
            <Timepicker v-model="closingTime" format="HH:mm" />

            <b-button variant="success" @click="generateCommand">Generar Comando</b-button>

            <div v-if="generatedCommand">
              <h4>Comando generado:</h4>
              <span>{{ generatedCommand }}</span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Timepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';
import { format } from 'date-fns';

export default {
  components: {
    Datepicker,
    Timepicker
  },
  data() {
    return {
      pollTitle: '',
      answers: [''],
      closingDate: '',
      closingTime: '',
      generatedCommand: ''
    };
  },
  methods: {
    addAnswer() {
      this.answers.push('');
    },
    removeAnswer(index) {
      this.answers.splice(index, 1);
    },
    generateCommand() {
      if (!this.pollTitle || this.answers.length < 2 || !this.closingDate || !this.closingTime) {
        alert('Por favor, completa todos los campos correctamente.');
        return;
      }

      const date = new Date(this.closingDate);
      const [hours, minutes] = this.closingTime.split(':');
      date.setHours(hours, minutes);

      const formattedDate = format(date, 'dd-MM-yyyy HH:mm');

      const command = `/createpoll ${this.pollTitle}; ${this.answers.filter(answer => answer).join('; ')}; ${formattedDate}`;
      this.generatedCommand = command;
    }

  }
};
</script>

<style scoped>
button {
  margin: 10px 0;
}
code {
  background-color: #f8f9fa;
  padding: 10px;
  display: block;
  margin-top: 10px;
  border-radius: 5px;
}
</style>
