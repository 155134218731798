<template>
  <b-container>
    <b-row>
      <b-col md="8" offset-md="2">
        <b-card title="Añadir Nuevo Juego">
          <div v-if="error" class="text-danger">{{ error }}</div>

          <!-- Barra de búsqueda para juegos en BGG -->
          <b-form-group label="Buscar en BGG">
            <b-input-group>
              <b-form-input v-model="bggQuery" placeholder="Ingrese el nombre del juego"></b-form-input>
              <b-button variant="primary" @click="searchBGGGames">Buscar</b-button>
            </b-input-group>
            <b-list-group v-if="bggGames.length > 0">
              <b-list-group-item
                  v-for="game in bggGames"
                  :key="game.id"
                  @click="selectBGGGame(game)"
                  style="cursor: pointer;"
              >
                {{ game.name.value }}
              </b-list-group-item>
            </b-list-group>
          </b-form-group>

          <b-form @submit.prevent="submitGame">
            <b-form-group label="Título" label-for="title">
              <b-form-input
                  id="title"
                  v-model="game.title"
                  required
                  placeholder="Ingrese el título del juego"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Descripción" label-for="description">
              <b-form-textarea
                  id="description"
                  v-model="game.description"
                  placeholder="Ingrese una descripción del juego"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label="Tipo de Juego" label-for="gameType">
              <b-form-select
                  id="gameType"
                  v-model="game.game_type"
                  :options="gameTypes"
                  required
              ></b-form-select>
            </b-form-group>

            <div v-if="game.game_type === 'boardgame'">
              <b-form-group label="Mínimo de Jugadores" label-for="minPlayers">
                <b-form-input
                    id="minPlayers"
                    type="number"
                    v-model="game.min_players"
                    placeholder="Ingrese el número mínimo de jugadores"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Máximo de Jugadores" label-for="maxPlayers">
                <b-form-input
                    id="maxPlayers"
                    type="number"
                    v-model="game.max_players"
                    placeholder="Ingrese el número máximo de jugadores"
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Tiempo Medio de Juego (minutos)" label-for="avgPlaytime">
                <b-form-input
                    id="avgPlaytime"
                    type="number"
                    v-model="game.avg_playtime"
                    placeholder="Ingrese el tiempo medio de juego en minutos"
                ></b-form-input>
              </b-form-group>
            </div>

            <b-form-group label="Idioma" label-for="language">
              <b-form-input
                  id="language"
                  v-model="game.language"
                  placeholder="Ingrese el idioma del juego"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="BGG ID" label-for="bggId">
              <b-form-input
                  id="bggId"
                  v-model="game.bgg_id"
                  placeholder="ID del juego en BoardGameGeek"
                  readonly
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Código QR (Opcional)">
              <b-button variant="secondary" @click="toggleQrScanner">
                {{ qrScannerActive ? 'Cerrar Lector QR' : 'Activar Lector QR' }}
              </b-button>
              <div v-if="qrScannerActive" class="mt-3">
                <qrcode-stream @decode="onQrDecode" @init="onInit"></qrcode-stream>
                <div v-if="qrError" class="text-danger">{{ qrError }}</div>
                <div v-if="game.qr_code">
                  <b-badge variant="success">Código QR asignado: {{ game.qr_code }}</b-badge>
                </div>
              </div>
            </b-form-group>

            <b-button type="submit" variant="primary">Añadir Juego</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref } from 'vue';
import InventoryService from '@/services/inventoryService';
import BGGService from '@/services/bggService';
import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  components: {
    QrcodeStream,
  },
  setup() {
    const game = ref({
      title: '',
      description: '',
      game_type: '',
      min_players: null,
      max_players: null,
      avg_playtime: null,
      language: '',
      qr_code: '',
      bgg_id: null,
    });

    const gameTypes = [
      { value: 'boardgame', text: 'Mesa' },
      { value: 'roleplay', text: 'Rol' },
      { value: 'other', text: 'Otro' },
    ];

    const bggQuery = ref('');
    const bggGames = ref([]);
    const error = ref(null);
    const qrError = ref(null);
    const qrScannerActive = ref(false);

    const submitGame = async () => {
      try {
        await InventoryService.addGame(game.value);
        alert('Juego añadido correctamente');
      } catch (err) {
        error.value = 'No se pudo añadir el juego. Intenta de nuevo más tarde.';
      }
    };

    const searchBGGGames = async () => {
      try {
        bggGames.value = await BGGService.searchGames(bggQuery.value);
      } catch (err) {
        error.value = 'No se pudieron buscar juegos en BGG. Intenta de nuevo más tarde.';
      }
    };

    const selectBGGGame = async (bggGame) => {
      try {
        // Guardar el ID de BGG
        game.value.bgg_id = bggGame.id;

        // Obtener detalles más completos del juego seleccionado
        const gameDetails = await BGGService.getGameDetails(bggGame.id);

        // Obtener el nombre principal del juego (type: "primary")
        const primaryName = Array.isArray(gameDetails.name)
            ? gameDetails.name.find((name) => name.type === "primary")?.value
            : gameDetails.name.value;

        // Actualizar los datos del juego con la información del resultado seleccionado
        game.value.title = primaryName || 'Sin título';
        game.value.description = gameDetails.description || `Publicado en ${bggGame.yearpublished?.value || 'N/A'}`;
        game.value.game_type = 'boardgame';
        game.value.min_players = gameDetails.minplayers?.value || null;
        game.value.max_players = gameDetails.maxplayers?.value || null;
        game.value.avg_playtime = gameDetails.playingtime?.value || null;

        // Limpiar la lista de resultados de BGG
        bggGames.value = [];
      } catch (err) {
        error.value = 'No se pudo seleccionar el juego. Intenta de nuevo.';
      }
    };

    const onQrDecode = (decodedString) => {
      game.value.qr_code = decodedString;
    };

    const toggleQrScanner = () => {
      qrScannerActive.value = !qrScannerActive.value;
    };

    return {
      game,
      gameTypes,
      bggQuery,
      bggGames,
      error,
      qrError,
      qrScannerActive,
      submitGame,
      searchBGGGames,
      selectBGGGame,
      onQrDecode,
      toggleQrScanner,
    };
  },
};
</script>

<style scoped>
.text-danger {
  color: red;
}
</style>
