import { createRouter, createWebHistory } from 'vue-router';
import Login from '@/components/LoginComponent';
import Register from '@/components/RegisterComponent';
import Profile from '@/components/ProfileComponent';
import Subscription from '@/components/SubscriptionComponent';
import UserList from "@/components/UserListComponent";
import UpdateProfileComponent from "@/components/UpdateProfileComponent"
import AuthService from '@/services/authService';
import { checkInventoryManager, checkSubscription, checkUser } from "@/router/auth.js";
import PollBotComponent from "@/components/PollBotComponent.vue";
import PasswordResetRequestComponent from "@/components/PasswordResetRequestComponent.vue";
import PasswordResetConfirmComponent from "@/components/PasswordResetConfirmComponent.vue";
import GameList from "@/components/inventory/GameListComponent.vue";
import AddGame from "@/components/inventory/AddGameComponent.vue";
import PurBotComponent from "@/components/PurBotComponent.vue";

const routes = [
    {
        path: '/login',
        component: Login,
        beforeEnter: (to, from, next) => {
            if (AuthService.getCurrentUser()) {
                next('/profile');
            } else {
                next();
            }
        }
    },
    {
        path: '/register',
        component: Register,
        beforeEnter: (to, from, next) => {
            if (AuthService.getCurrentUser()) {
                next('/profile');
            } else {
                next();
            }
        }
    },
    {
        path: '/profile/:id?',
        component: Profile,
        beforeEnter: checkUser
    },
    {
        path: '/update-profile/:id',
        name: 'UpdateProfile',
        component: UpdateProfileComponent,
        beforeEnter: async (to, from, next) => {
            const user = await AuthService.getCurrentUser();
            if (user) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/subscribe',
        component: Subscription,
        beforeEnter: checkSubscription
    },
    {
        path: '/users',
        component: UserList,
        beforeEnter: checkUser
    },
    {
        path: '/pollbot',
        component: PollBotComponent,
    },
    {
        path: '/purbot',
        component: PurBotComponent,
    },
    {
        path: '/password-reset',
        component: PasswordResetRequestComponent,
    },
    {
        path: '/reset-password',
        component: PasswordResetConfirmComponent,
    },
    {
        path: '/games',
        component: GameList,
        beforeEnter: checkUser
    },
    {
        path: '/add-game',
        component: AddGame,
        beforeEnter: checkInventoryManager
    },
    {
        path: '/',
        redirect: '/profile'
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
