<template>
  <b-container class="password-reset-request">
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Restablecer contraseña">
          <b-alert v-model="displayMessage" variant="success" dismissible>{{ message }}</b-alert>
          <b-alert v-model="displayError" variant="danger" dismissible>{{ error }}</b-alert>
          <b-form @submit.prevent="requestPasswordReset">
            <b-form-group label="Correo electrónico:" label-for="email">
              <b-form-input
                  id="email"
                  v-model="email"
                  type="email"
                  required
              ></b-form-input>
            </b-form-group>
            <b-button class="reset-button" type="submit" variant="primary" :disabled="loading">
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner> Enviando...
              </span>
              <span v-else>Enviar solicitud</span>
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref } from 'vue';
import AuthService from '@/services/authService';

export default {
  setup() {
    const email = ref('');
    const displayMessage = ref(false);
    const message = ref('');
    const displayError = ref(false);
    const error = ref('');
    const loading = ref(false);

    const requestPasswordReset = () => {
      loading.value = true;
      AuthService.requestPasswordReset({ email: email.value })
          .then(() => {
            message.value =
                'Si este correo electrónico está registrado, hemos enviado instrucciones para restablecer tu contraseña.';
            displayMessage.value = true;
            error.value = '';
            displayError.value = false;
          })
          .catch((err) => {
            console.error(err);
            displayError.value = true;
            error.value = 'Ha ocurrido un error. Por favor, intenta nuevamente.';
            displayMessage.value = false;
            message.value = '';
          })
          .finally(() => {
            loading.value = false;
          });
    };

    return {
      email,
      message,
      displayMessage,
      error,
      displayError,
      loading,
      requestPasswordReset,
    };
  },
};
</script>

<style>
.reset-button {
  margin-top: 20px;
}
</style>
