import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router/index.js';
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import {createBootstrap} from 'bootstrap-vue-next'

// Import Bootstrap and BootstrapVueNext CSS files
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';

// Import specific components you need from bootstrap-vue-next
import { BLink, BToast, BTable, BNavbar, BNavbarBrand, BNavbarToggle, BCollapse, BNavbarNav, BNavItem, BContainer, BRow, BCol, BCard, BCardTitle, BCardText, BCardBody, BCardHeader, BForm, BFormGroup, BFormInput, BFormCheckbox, BFormSelect, BButton, BAlert, BInputGroup, BBadge, BFormTextarea, BListGroup, BListGroupItem, BToastOrchestrator } from 'bootstrap-vue-next';

const app = createApp(App);

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app.use(router);
app.use(pinia)

app.use(createBootstrap());

// Register the components you need globally
app.component('BLink', BLink);
app.component('BNavbar', BNavbar);
app.component('BNavbarBrand', BNavbarBrand);
app.component('BNavbarToggle', BNavbarToggle);
app.component('BCollapse', BCollapse);
app.component('BNavbarNav', BNavbarNav);
app.component('BNavItem', BNavItem);
app.component('BContainer', BContainer);
app.component('BRow', BRow);
app.component('BCol', BCol);
app.component('BCard', BCard);
app.component('BCardTitle', BCardTitle);
app.component('BCardText', BCardText);
app.component('BCardBody', BCardBody);
app.component('BCardHeader', BCardHeader);
app.component('BForm', BForm);
app.component('BFormGroup', BFormGroup);
app.component('BInputGroup', BInputGroup);
app.component('BFormInput', BFormInput);
app.component('BFormSelect', BFormSelect);
app.component('BButton', BButton);
app.component('BAlert', BAlert);
app.component('BTable', BTable);
app.component('BToast', BToast);
app.component('BFormCheckbox', BFormCheckbox);
app.component('BBadge', BBadge);
app.component('BListGroup', BListGroup);
app.component('BListGroupItem', BListGroupItem);
app.component('BFormTextarea', BFormTextarea);
app.component('BToastOrchestrator', BToastOrchestrator);

app.mount('#app');
