<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Editar Perfil">
          <b-form @submit.prevent="updateProfile">
            <b-form-group label="Nombre de usuario">
              <b-form-input v-model="form.username" required></b-form-input>
            </b-form-group>
            <b-form-group label="Nombre">
              <b-form-input v-model="form.first_name" required></b-form-input>
            </b-form-group>
            <b-form-group label="Apellidos">
              <b-form-input v-model="form.last_name" required></b-form-input>
            </b-form-group>
            <b-form-group label="Correo electrónico">
              <b-form-input type="email" v-model="form.email" required></b-form-input>
            </b-form-group>
            <b-form-group label="Número de teléfono">
              <b-form-input v-model="form.phone_number" required></b-form-input>
            </b-form-group>
            <b-form-group label="DNI">
              <b-form-input v-model="form.dni" required></b-form-input>
            </b-form-group>
            <b-form-group label="Dirección">
              <b-form-input v-model="form.address" required></b-form-input>
            </b-form-group>
            <b-form-group label="Usuario de telegram">
              <b-form-input v-model="form.telegram_username"></b-form-input>
            </b-form-group>
            <b-button class="save-changes" type="submit" variant="primary">Guardar Cambios</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';

export default {
  setup() {
    const router = useRouter();
    const form = ref({
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      dni: '',
      telegram_username: '',
      address: '',
    });

    onMounted(() => {
      AuthService.getUserProfile().then(
          response => {
            form.value = {
              username: response.data.username,
              first_name: response.data.first_name,
              last_name: response.data.last_name,
              email: response.data.email,
              phone_number: response.data.phone_number,
              dni: response.data.dni,
              telegram_username: response.data.telegram_username,
              address: response.data.address
            };
          },
          error => {
            console.error(error);
          }
      );
    });

    const updateProfile = () => {
      AuthService.updateUserProfile(form.value).then(
          () => {
            router.push('/profile/');
          },
          error => {
            console.error(error);
            alert('Error al actualizar el perfil');
          }
      );
    };

    return {
      form,
      updateProfile
    };
  }
};
</script>

<style>
.save-changes {
  background-color: #e01414 !important; /* Custom background color */
  color: #fff;               /* Custom text color */
  margin-top:20px;
}

</style>
