import { defineStore } from "pinia";
import SecureLS from "secure-ls";

const ls = new SecureLS({ encodingType: 'aes' });

export const useUserStore = defineStore('userStore', {
    state: () => ({
        loggedInUser: null,
        userLoggedIn: false,
        accessToken: '',
        refreshToken: '',
        groups: [],
    }),
    getters: {
        getLoggedInUser: (state) => state.loggedInUser,
        isUserLoggedIn: (state) => state.userLoggedIn,
        getAccessToken: (state) => state.accessToken,
        getRefreshToken: (state) => state.refreshToken,
        getUserGroups: (state) => state.groups,
    },
    actions: {
        saveAccessToken(accessToken) {
            this.accessToken = accessToken;
        },
        saveUserTokens(userTokens) {
            this.accessToken = userTokens.access;
            this.refreshToken = userTokens.refresh;
            this.userLoggedIn = true;
        },
        saveLoggedInUser(loggedInUser) {
            this.loggedInUser = loggedInUser;
            this.userLoggedIn = true;
            if (loggedInUser.groups) {
                this.groups = loggedInUser.groups
            }
        },
        cleanLoggedInUser() {
            this.$reset();
            ls.remove('userStore');
        },
        updateHasActiveSubscription(hasActiveSubscription) {
            if (this.loggedInUser) {
                this.loggedInUser.has_active_subscription = hasActiveSubscription;
            } else {
                console.warn('No loggedInUser found when trying to update has_active_subscription');
            }
        }
    },
    persist: {
        enabled: true,
        strategies: [
            {
                key: 'userStore',
                storage: localStorage,
            },
        ],
    },
});
