<template>
  <div>
    <div class="terms-container" ref="termsContainer" @scroll="handleScroll">
      <h1>{{ title }}</h1>
      <p>Autorización informada para el uso de
        datos personales</p>
        <p>        Yo, a la entidad Valentia Lúdica, con NIF G98835275 y domicilio en Calle Velázquez, 24 Bajo, Valencia
        46018, para recabar y tratar los datos personales, consistentes en datos de identificación para la
        finalidad de comunicación con la persona interesada, mantenimiento de los listados de socios y
        colaboradores de la asociación.</p>
      <p>
        La aportación de dichos datos es obligatoria para poder cumplir con la anterior finalidad. El destinatario
        de los datos será únicamente el responsable del fichero indicado en el párrafo anterior , que no cederá
        los mismos a entidad alguna, salvo requerimiento legal si ello fuera absolutamente necesario, así como
        las comunicaciones realizadas a los encargados del tratamiento que colaboren en el cumplimiento de
        la finalidad indicada. La base jurídica de este tratamiento es en todo caso su consentimiento. Usted
        podrá retirar este consentimiento en cualquier momento, sin que ello afecte al legítimo tratamiento
        realizado hasta la fecha. También puede ejercitar los derechos de acceso, rectificación, cancelación,
        limitación, oposición y portabilidad ante el responsable, en la dirección anteriormente indicada o
        escribiendo un correo electrónico a secretaria@valentialudica.com, adjuntando su documento de
        identidad. Asimismo podrá presentar reclamación por el tratamiento realizado ante la Agencia Española
        de Protección de Datos. Estos datos serán conservados exclusivamente hasta cumplir con la finalidad
        prevista, salvo que legalmente se prevea un plazo mayor de conservación. Asimismo, usted consiente
        en que el responsable trate sus propios datos personales contenidos en el presente documento, con
        la única finalidad de gestionar las autorizaciones informadas. Todo ello con el mismo contenido y
        derechos contemplados en el párrafo anterior.
      </p>
    </div>
    <b-form-group label="Lee el texto completo antes de aceptarlo" :label-for="identifier">
      <b-form-checkbox :id="identifier" v-model="hasAccepted" name="check-button" switch :disabled="false"
        @change="acceptTerms" />
    </b-form-group>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    identifier: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const termsContainer = ref(null);
    const hasRead = ref(false);
    const hasAccepted = ref(false);

    const handleScroll = () => {
      const container = termsContainer.value;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        hasRead.value = true;
      }
    };

    const acceptTerms = () => {
      emit('update:accepted-terms', hasAccepted);
    };

    return {
      termsContainer,
      hasRead,
      hasAccepted,
      handleScroll,
      acceptTerms
    };
  }
};
</script>

<style scoped>
.terms-container {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
</style>
