// router/auth.js
import { useUserStore } from '@/stores/userStore';
import AuthService from '@/services/authService';

export async function checkUser(to, from, next) {
    const userStore = useUserStore();
    let loggedInUser = userStore.getLoggedInUser;

    if (!loggedInUser) {
        try {
            const response = await AuthService.getUserProfile();
            userStore.saveLoggedInUser(response.data);
            loggedInUser = response.data;
        } catch (error) {
            return next('/login');
        }
    }

    if (loggedInUser) {
        next();
    } else {
        next('/login');
    }
}

export async function checkSubscription(to, from, next) {
    const userStore = useUserStore();
    let loggedInUser = userStore.getLoggedInUser;

    if (!loggedInUser) {
        try {
            const response = await AuthService.getUserProfile();
            userStore.saveLoggedInUser(response.data);
            loggedInUser = response.data;
        } catch (error) {
            return next('/login');
        }
    }

    if (loggedInUser && !loggedInUser.has_active_subscription) {
        next();
    } else {
        next('/profile');
    }
}

export async function checkInventoryManager(to, from, next) {
    const userStore = useUserStore();
    let loggedInUser = userStore.getLoggedInUser;

    if (!loggedInUser) {
        try {
            const response = await AuthService.getUserProfile();
            userStore.saveLoggedInUser(response.data);
            loggedInUser = response.data;
        } catch (error) {
            return next('/login');
        }
    }

    if (loggedInUser && loggedInUser.groups && loggedInUser.groups.includes('Inventory Managers')) {
        next();
    } else {
        next('/games');
    }
}
