<template>
  <b-container class="password-reset-confirm">
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Establecer nueva contraseña">
          <b-alert v-model="displayMessage" variant="success" dismissible>{{ message }}</b-alert>
          <b-alert v-model="displayError" variant="danger" dismissible>{{ error }}</b-alert>
          <b-form @submit.prevent="setNewPassword">
            <b-form-group label="Nueva contraseña:" label-for="password">
              <b-form-input
                  id="password"
                  v-model="password"
                  type="password"
                  required
              ></b-form-input>
            </b-form-group>
            <b-form-group label="Confirma tu contraseña:" label-for="confirmPassword">
              <b-form-input
                  id="confirmPassword"
                  v-model="confirmPassword"
                  type="password"
                  required
              ></b-form-input>
            </b-form-group>
            <b-button class="reset-button" type="submit" variant="primary" :disabled="loading">
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner> Enviando...
              </span>
              <span v-else>Restablecer contraseña</span>
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import AuthService from '@/services/authService';

export default {
  setup() {
    const password = ref('');
    const confirmPassword = ref('');
    const message = ref('');
    const displayMessage = ref(false);
    const error = ref('');
    const displayError = ref(false);
    const loading = ref(false);

    const route = useRoute();
    const router = useRouter();

    const uidb64 = route.query.uidb64;
    const token = route.query.token;

    if (!uidb64 || !token) {
      error.value = 'El enlace de restablecimiento no es válido.';
      displayError.value = true;
    }

    const setNewPassword = () => {
      if (password.value !== confirmPassword.value) {
        error.value = 'Las contraseñas no coinciden.';
        return;
      }

      loading.value = true;
      AuthService.resetPassword({
        password: password.value,
        uidb64,
        token,
      })
          .then(() => {
            message.value = 'Tu contraseña ha sido restablecida exitosamente.';
            displayMessage.value = true;
            error.value = '';
            displayError.value = false;
            setTimeout(() => {
              router.push('/login');
            }, 3000);
          })
          .catch((err) => {
            console.error(err);
            error.value = 'El enlace no es válido o ha expirado.';
            displayError.value = true;
            message.value = '';
            displayMessage.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
    };

    return {
      password,
      confirmPassword,
      message,
      displayMessage,
      error,
      displayError,
      loading,
      setNewPassword,
    };
  },
};
</script>

<style>
.reset-button {
  margin-top: 20px;
}
</style>
