<template>
  <div>
    <div class="terms-container" ref="termsContainer" @scroll="handleScroll">
      <h1>{{ title }}</h1>
      <p>Autorización informada para el uso de
        datos personales</p>
        <p>        Yo, a la entidad Valentia Lúdica, con NIF G98835275 y domicilio en Calle Velázquez, 24 Bajo, Valencia
        46018, para recabar y tratar los datos personales, consistentes en datos de identificación para la
        finalidad de comunicación con la persona interesada, mantenimiento de los listados de socios y
        colaboradores de la asociación.</p>
      <p>
        Para que esta asociación pueda fijar su imagen, especialmente mediante fotografías o
vídeos que puedan realizarse durante las actividades que se relacionan a continuación:
● Actividades ordinarias en grupo
● Actividades complementarias
● Actividades realizadas fuera del local
● Otras actividades relacionadas con la asociación en las que intervenga la persona
interesada.
Asimismo, autorizo a la asociación a hacer uso de las imágenes tomadas y voz,
distribuyéndolas y comunicándolas al público, siempre dentro de la mencionada finalidad,
especialmente mediante la publicación en los siguientes medios:
● Página web de la asociación y publicaciones en redes sociales de la asociación.
● Montajes multimedia (CD o DVD con fotos, películas, etc.).
● Documentos informativos de la actividad, como cartas de servicios, trípticos
informativos, carteles, promocionales de actividades, etc.
De conformidad con lo establecido en el artículo 13 RGPD, declaro conocer los siguientes
aspectos:
● Que las imágenes tomadas podrán ser incorporadas a un fichero cuyo responsable
es la asociación Valentia Lúdica, con NIF G98835275 y domicilio en Calle
Velázquez, 24 Bajo, Valencia 46018, que podrá tratar las mismas y hacer uso de
ellas en los términos y para la finalidad fijados en este documento.
● El destinatario de estos datos será únicamente dicho responsable, quien no realizará
cesión alguna de los mismos, salvo las comunicaciones a los encargados del
tratamiento que colaboren en la prestación de los servicios a los que se refiere el
tratamiento.
● La base jurídica de dicho tratamiento es el propio consentimiento, el cual podrá ser
retirado en cualquier momento, sin que ello afecte al legítimo tratamiento realizado
hasta dicha fecha.
● Dichas imágenes serán conservadas por el tiempo exclusivo para dar cumplimiento
a la finalidad para la que fueron recabadas y mientras no prescriban los derechos
que pueda ejercitar contra el responsable, salvo norma que disponga un plazo
superior.
● Que la no autorización expresada anteriormente será tenida en cuenta por la
asociación a efectos de evitar en lo posible recopilar datos de la persona como
pueden ser la toma de imágenes. En todo caso, habiendo sido tomada dicha imagen
a través de fotografía, video o cualquier otro medio de captación se procederá a
distorsionar sus rasgos diferenciadores, sobre todo cuando en la foto concurra su
imagen con la de otras personas que sí hayan autorizado, en los términos aquí
previstos, al uso, tratamiento y cesión de su imagen.
● Que en cualquier momento puedo ejercer los derechos de acceso, rectificación,
cancelación, oposición, limitación y portabilidad, mediante escrito dirigido a los
responsables de la asociación en la dirección arriba descrita o vía correo electrónico
a secretaria@valentialudica.com incluyendo una copia del documento de identidad.
Asimismo tengo la posibilidad de presentar reclamación ante la Agencia Española de
Protección de Datos.
● Consiento el tratamiento de mis propios datos personales contemplados en el
presente documento, con la única finalidad de gestionar las autorizaciones
informadas. Todo ello con las mismas características y derechos contemplados en
los párrafos anteriores. 
      </p>
    </div>
    <b-form-group label="Lee el texto completo antes de aceptarlo" :label-for="identifier">
      <b-form-checkbox :id="identifier" v-model="hasAccepted" name="check-button" switch :disabled="false"
        @change="acceptTerms" />
    </b-form-group>
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  props: {
    identifier: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  setup(props, { emit }) {
    const termsContainer = ref(null);
    const hasRead = ref(false);
    const hasAccepted = ref(false);

    const handleScroll = () => {
      const container = termsContainer.value;
      if (container.scrollTop + container.clientHeight >= container.scrollHeight) {
        hasRead.value = true;
      }
    };

    const acceptTerms = () => {
      emit('update:accepted-terms', hasAccepted);
    };

    return {
      termsContainer,
      hasRead,
      hasAccepted,
      handleScroll,
      acceptTerms
    };
  }
};
</script>

<style scoped>
.terms-container {
  height: 300px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
}

button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
</style>
