<template>
  <b-container class="login">
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Iniciar sesión">
          <b-alert v-model="displayErrorMessage" variant="danger" dismissible>{{ message }}</b-alert>
          <b-form @submit.prevent="login">
            <b-form-group label="Nombre de usuario:" label-for="username">
              <b-form-input id="username" v-model="user.username" required></b-form-input>
            </b-form-group>
            <b-form-group label="Contraseña:" label-for="password">
              <b-form-input type="password" id="password" v-model="user.password" required></b-form-input>
            </b-form-group>
            <b-button class="login-button" type="submit" variant="primary" :disabled="loading">
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner> Iniciando...
              </span>
              <span v-else>Iniciar sesión</span>
            </b-button>
            <b-button class="password-reset-button" variant="secondary" @click="goToPasswordReset">¿Olvidaste tu contraseña?</b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import { useToast } from 'bootstrap-vue-next';

export default {
  setup() {
    const user = ref({
      username: '',
      password: '',
    });

    const message = ref('');
    const displayErrorMessage = ref(false);
    const loading = ref(false);

    const router = useRouter();
    const toast = useToast();

    const login = () => {
      loading.value = true;
      AuthService.login(user.value)
        .then(() => {
          router.push('/profile');
          window.location.reload();
        })
        .catch(() => {
          message.value = 'Usuario o contraseña incorrectos. Por favor, prueba de nuevo.';
          displayErrorMessage.value = true;
          toast.show({
            props: {
              body: 'No se ha podido iniciar sesión. Por favor, prueba de nuevo.',
              title: 'Error al iniciar sesión',
              variant: 'danger',
            },
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const goToPasswordReset = () => {
      router.push('/password-reset');
    };

    return {
      user,
      login,
      message,
      displayErrorMessage,
      loading,
      goToPasswordReset
    };
  },
};
</script>

<style>
.login {
  color: #fff;
  margin-bottom: 20px;
}

.login .login-button {
  margin-top: 20px;
}

.login .password-reset-button {
  margin-top: 20px;
  margin-left: 20px;
}

.navbar .navbar-brand {
  color: #fff;
}
</style>
