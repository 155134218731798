// src/services/bggService.js
import api from './api';

class BGGService {
    async searchGames(query) {
        try {
            const response = await api.get('inventory/bgg/search/', {
                params: { query },
            });
            // Ajuste para acceder a la lista de juegos correctamente
            const items = response.data.items?.item || [];
            return Array.isArray(items) ? items : [items]; // Si solo hay un resultado, convertirlo a array
        } catch (error) {
            console.error('Error al buscar en BGG:', error);
            throw error;
        }
    }

    async getGameDetails(gameId) {
        try {
            const response = await api.get(`inventory/bgg/game/${gameId}/`);
            return response.data.items.item; // Devuelve los detalles del juego
        } catch (error) {
            console.error('Error al obtener detalles del juego:', error);
            throw error;
        }
    }
}

export default new BGGService();
