<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Crear Evento">
          <div>
            <p><strong>Tipo de evento:</strong></p>
            <b-form-select v-model="eventType" :options="eventTypes"></b-form-select>

            <p><strong>Nombre del evento:</strong></p>
            <b-form-input v-model="eventName" placeholder="Introduce el nombre del evento" />

            <template v-if="eventType !== 'casual'">
              <p><strong>Fecha de inicio:</strong></p>
              <Datepicker v-model="startDate" :format="'YYYY-MM-DD'" />

              <template v-if="!isFullDayEvent">
                <p><strong>Hora de inicio:</strong></p>
                <Timepicker v-model="startTime" format="HH:mm" />

                <p><strong>Fecha de fin:</strong></p>
                <Datepicker v-model="endDate" :format="'YYYY-MM-DD'" />

                <p><strong>Hora de fin:</strong></p>
                <Timepicker v-model="endTime" format="HH:mm" />
              </template>

              <b-form-checkbox v-model="isFullDayEvent">
                Evento de día completo (sin horas)
              </b-form-checkbox>
            </template>

            <b-button variant="success" @click="generateCommand">Generar Comando</b-button>

            <div v-if="generatedCommand">
              <h4>Comando generado:</h4>
              <span>{{ generatedCommand }}</span>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import Timepicker from 'vue3-timepicker';
import 'vue3-timepicker/dist/VueTimepicker.css';
import { format } from 'date-fns';

export default {
  components: {
    Datepicker,
    Timepicker
  },
  data() {
    return {
      eventType: 'createrol',
      eventTypes: [
        { value: 'createrol', text: 'Evento de Rol' },
        { value: 'createmesa', text: 'Evento de Mesa' },
        { value: 'createcine', text: 'Evento de Cine' },
        { value: 'createwar', text: 'Evento de War' },
        { value: 'creategen', text: 'Evento General' },
        { value: 'casualrol', text: 'Casual Rol (90 minutos)' },
        { value: 'casualmesa', text: 'Casual Mesa (90 minutos)' },
        { value: 'casualcine', text: 'Casual Cine (90 minutos)' },
        { value: 'casualwar', text: 'Casual War (90 minutos)' },
        { value: 'casualgen', text: 'Casual General (90 minutos)' }
      ],
      eventName: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      isFullDayEvent: false,
      generatedCommand: ''
    };
  },
  methods: {
    generateCommand() {
      if (!this.eventName) {
        alert('Por favor, introduce el nombre del evento.');
        return;
      }

      let command = `/${this.eventType} ${this.eventName}`;

      if (this.eventType.startsWith('casual')) {
        this.generatedCommand = command;
        return;
      }

      if (!this.startDate) {
        alert('Por favor, selecciona una fecha de inicio.');
        return;
      }

      if (this.isFullDayEvent) {
        command += `, ${format(new Date(this.startDate), 'dd-MM-yyyy')}`;
        if (this.endDate) {
          command += `, ${format(new Date(this.endDate), 'dd-MM-yyyy')}`;
        }
      } else {
        if (!this.startTime || !this.endDate || !this.endTime) {
          alert('Por favor, completa todas las fechas y horas correctamente.');
          return;
        }

        const startDateTime = `${format(new Date(this.startDate), 'dd-MM-yyyy')} ${this.startTime}`;
        const endDateTime = `${format(new Date(this.endDate), 'dd-MM-yyyy')} ${this.endTime}`;

        command += `, ${startDateTime}, ${endDateTime}`;
      }

      this.generatedCommand = command;
    }
  }
};
</script>

<style scoped>
button {
  margin: 10px 0;
}
code {
  background-color: #f8f9fa;
  padding: 10px;
  display: block;
  margin-top: 10px;
  border-radius: 5px;
}
</style>
