<template>
  <b-container>
    <b-row>
      <b-col md="6" offset-md="3">
        <b-card title="Registro">
          <b-alert
            ref="errorAlert"
            v-model="displayError"
            variant="danger"
            dismissible
            @dismissed="message = ''"
          >
            <div v-html="message"></div> <!-- Render message as HTML -->
          </b-alert>
          <b-form @submit.prevent="register">
            <b-form-group label="Nombre de usuario:" label-for="username">
              <b-form-input id="username" v-model="user.username" required></b-form-input>
            </b-form-group>
            <b-form-group label="Nombre:" label-for="first_name">
              <b-form-input id="firstname" v-model="user.first_name" required></b-form-input>
            </b-form-group>
            <b-form-group label="Apellidos:" label-for="last_name">
              <b-form-input id="lastname" v-model="user.last_name" required></b-form-input>
            </b-form-group>
            <b-form-group label="Correo electrónico:" label-for="email">
              <b-form-input type="email" id="email" v-model="user.email" required></b-form-input>
            </b-form-group>
            <b-form-group label="Contraseña:" label-for="password">
              <b-input-group>
                <b-form-input
                    :type="passwordFieldType"
                    id="password"
                    v-model="user.password"
                    required
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="togglePasswordVisibility" variant="outline-secondary" class="d-flex align-items-center">
                    <svg v-if="passwordFieldType === 'password'" xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                      <path d="M12 4.5C7.305 4.5 3.184 7.412 1.5 12c1.684 4.588 5.805 7.5 10.5 7.5s8.816-2.912 10.5-7.5C20.816 7.412 16.695 4.5 12 4.5zm0 12c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5zm0-7c-1.381 0-2.5 1.119-2.5 2.5S10.619 14.5 12 14.5s2.5-1.119 2.5-2.5S13.381 9.5 12 9.5z"/>
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                      <path d="M12 5.5c4.68 0 8.674 2.805 10.126 7C20.674 17.195 16.68 20 12 20s-8.674-2.805-10.126-7C3.326 8.305 7.32 5.5 12 5.5m0-1C6.92 4.5 2.48 7.36.96 12c1.52 4.64 5.96 7.5 11.04 7.5 5.08 0 9.52-2.86 11.04-7.5-1.52-4.64-5.96-7.5-11.04-7.5zM12 9c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3m0-1c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 6c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>
                    </svg>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Número de teléfono:" label-for="phone_number">
              <b-form-input id="phone_number" v-model="user.phone_number"></b-form-input>
            </b-form-group>
            <b-form-group label="DNI:" label-for="dni">
              <b-form-input id="dni" v-model="user.dni" required></b-form-input>
            </b-form-group>
            <b-form-group label="Dirección:" label-for="address">
              <b-form-input type="text" id="address" v-model="user.address" required></b-form-input>
            </b-form-group>
            <b-form-group label="Usuario de telegram:" label-for="telegram_username">
              <b-form-input id="telegram_username" v-model="user.telegram_username" required></b-form-input>
            </b-form-group>
            <PrivacyTermsDataComponent class="privacy-data" @update:accepted-terms="updateAcceptedTerms" identifier="privacyTerms" title="Privacidad de datos" />
            <PrivacyTermsImageComponent @update:accepted-terms="updateAcceptedImageTerms" identifier="privacyImageTerms" title="Privacidad de imagen" />
            <b-button class="register" type="submit" variant="primary" :disabled="!user.privacy_accepted || loading">
              <span v-if="loading">
                <b-spinner small label="Loading..."></b-spinner> Registrando...
              </span>
              <span v-else>Registrar</span>
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { nextTick, ref } from 'vue';
import { useRouter } from 'vue-router';
import AuthService from '@/services/authService';
import PrivacyTermsDataComponent from "@/components/register/PrivacyTermsDataComponent.vue";
import PrivacyTermsImageComponent from "@/components/register/PrivacyTermsImageComponent.vue";

export default {
  components: {
    PrivacyTermsDataComponent,
    PrivacyTermsImageComponent
  },
  setup() {
    const user = ref({
      username: '',
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone_number: '',
      dni: '',
      address: '',
      telegram_username: '',
      privacy_accepted: false,
      privacy_image_accepted: false
    });

    const passwordFieldType = ref('password'); // Field type for the password
    const message = ref('');
    const displayError = ref(false);
    const loading = ref(false); // Loading state
    const router = useRouter();
    const errorAlert = ref(null); // Reference to the alert element

    const register = () => {
      loading.value = true; // Set loading to true when form is submitted
      AuthService.register(user.value).then(
        () => {
          router.push('/login');
        }).catch(error => {
          if (error.response && error.response.data) {
            message.value = formatErrorMessages(error.response.data);
            displayError.value = true;
          } else {
            message.value = 'Ocurrió un error al registrar. Por favor, inténtelo de nuevo.';
          }
          nextTick(() => {
            scrollToAlert();
          });
        }).finally(() => {
          loading.value = false; // Reset loading state after the request finishes
        });
    };

    const togglePasswordVisibility = () => {
      passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password';
    };

    const formatErrorMessages = (errors) => {
      return `<ul>${Object.values(errors)
        .flat()
        .map((message) => `<li>${message}</li>`)
        .join('')}</ul>`;
    };

    const scrollToAlert = () => {
      if (errorAlert.value) {
        errorAlert.value.$el.scrollIntoView({ behavior: 'smooth' });
      }
    };

    const updateAcceptedTerms = (value) => {
      user.value.privacy_accepted = value;
    };

    const updateAcceptedImageTerms = (value) => {
      user.value.privacy_image_accepted = value;
    };

    return {
      user,
      passwordFieldType,
      message,
      displayError,
      errorAlert,
      register,
      togglePasswordVisibility,
      updateAcceptedTerms,
      updateAcceptedImageTerms,
      loading // Return loading state to use in template
    };
  }
};
</script>

<style>
.register {
  background-color: #e01414 !important; /* Custom background color */
  color: #fff;               /* Custom text color */
  margin-right: 20px;
}

.privacy-data {
  margin-top: 20px;
}

.eye-icon {
  cursor: pointer;
  padding: 0.5rem;
}

.eye-icon svg {
  width: 24px;
  height: 24px;
  fill: #555; /* Customize color of the icon */
}
</style>
